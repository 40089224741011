import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { refreshAccessToken, AppContextProvider } from "./AppContext";
import * as $ from 'jquery';
import { BrowserRouter as Router } from "react-router-dom";
// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/all.min.css";
import "./css/reset.css";
import "./css/common.css";
import "./css/comprehensive_style.css";
import "./css/template.css";
import "./css/slick.css";

refreshAccessToken().then((value) =>
ReactDOM.render(
  <AppContextProvider init={value}>
    {/* <Router> */}
      <App />
    {/* </Router> */}
  </AppContextProvider>,
  document.querySelector("#root")
)
);