import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import useAppContext from "./AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { NavLink } from "react-bootstrap";
import $ from "jquery";
import {PageView, initGA} from './app/tracking';
import TagManager from 'react-gtm-module'
import CompanyLogin from "./app/login/CompanyLogin";
import CustomerLogin from "./app/login/CustomerLogin";
import ProjectsList from "./app/project/ProjectsList";
import NewsList from "./app/news/NewsList";

const tagManagerArgs = {
    gtmId: 'GTM-MF6ZG9C'
}

TagManager.initialize(tagManagerArgs);

export default function App() {
  const appContext = useAppContext();

  const PROJECT_LIST = "company/project/list/before";
  const NEWS_LIST = "news/all";

  const [projectList, setProjectList] = React.useState([]);
  const [newsList, setNewsList] = React.useState([]);

  React.useEffect(() => {
    initGA();
    PageView();
    getProjectLists();
    getNewsLists();
  }, []);

  function getProjectLists(){
    appContext.getAxios().get(PROJECT_LIST).then((response)=>{
      setProjectList(response.data.result);
    },(error)=>{
        // alert.error(error.response.data.message);    
    })
  }

  function getNewsLists(){
    appContext.getAxios().get(`${NEWS_LIST}?limit=1`).then((response)=>{
      setNewsList(response.data);
    },(error)=>{
        // alert.error(error.response.data.message);    
    })
  }

  return (
    <Router>
      <Switch>
        <Route path="/">
        <div id="comprehensive_top">
    <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
      <div className="col-12 brand-row">
        <div className="logo-area">
          <a className="navbar-brand" href="/">
            <img className="navbar-logo1"  src={require('../src/images/header-logo.png')} width="170px height:auto;"  alt="AIK order ロゴ"/>
          </a>
        </div>
        <div className="common-div">
        <a className="navbar-tel" href="/">
            <img className="navbar-tel1" src={require('../src/images/telnamber.png')} width="200px height:auto;"  alt="AIK order ロゴ"/>
          </a>
          <div className="comprehensive_top_icon-area">
            <p className="text-left msg-box">
            <a className="btn btn-contact btn-sm text-white font-weight-bold text-custom" href="https://aik-security.co.jp/contact1"
              target="_blank" role="button">
              <i className="fas fa-envelope"></i> お問い合わせ</a>
          </p>
        </div>
        </div>
      </div>
    </nav>
    <header></header>
    <div className="jumbotron jumbotron-fluid mb-0" id="mainvisual"
      style={{background:`url(${require('../src/images/mainvisual_wide.jpg')}) center no-repeat`, backgroundSize:"cover"}}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img
              id="mv_ribbon"
              alt="MV Ribbon"
              src={require("../src/images/mv_ribbon.svg")}
            />
            <h1 className="text-left" id="main-visual-message">個人でも会社でも<br />警備の依頼を<br />もっと手軽に</h1>
            <p className="text-left">
              <a className="btn btn-contact btn-lg text-white font-weight-bold px-lg-4 py-lg-2"
                href="https://aik-security.co.jp/contact1" target="_blank" role="button">
                <i className="fas fa-envelope"></i> お問い合わせ
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* ログイン機能 */}
    <section>
      <div className="jumbotron mb-0 rounded-0 py-4 py-md-5" style={{backgroundColor:"#021327"}}>
        <div className="container px-xs-0">
          <div className="row mx-auto">
            <CompanyLogin></CompanyLogin>
            <CustomerLogin></CustomerLogin>
          </div>
        </div>
      </div>
    </section>
    {/* ログイン機能 ここまで */}

    {/* -- add 2021.08 -- */}
      <div className="container-fluid slick_logo_wrap">
        <div className="row">
          <div className="slick_logo">
            <div className="client_slide">
              {/* <p><img src={require('../src/images/logo_confiance.png')} alt="株式会社confianceロゴ"/></p>
              <p><img src={require('../src/images/logo_new_kindai.png')} alt="株式会社ニュー近代警備ロゴ"/></p>
              <p><img src={require('../src/images/logo_asahi.png')} alt="株式会社アサヒ安全業務社"/></p>
              <p><img src={require('../src/images/logo_esi.png')} alt="株式会社エルテスセキュリティインテリジェンスロゴ"/></p>
              <p><img src={require('../src/images/logo_homemade-tokyo.png')} alt="株式会社ホームメイド東京"/></p>
              <p><img src={require('../src/images/logo_midorigaoka.png')} alt="祐生会みどりヶ丘病院"/></p>
              <p><img src={require('../src/images/logo_stutcomes.png')} alt="株式会社S&T OUTCOMESロゴ"/></p>
              <p><img src={require('../src/images/logo_tachibana.png')} alt="タチバナエクステリアロゴ"/></p> */}
              <p><img src = {require('../src/images/latest/d_1.png')} alt = "Daiwa Co" /> </p>
              <p><img src = {require('../src/images/latest/force_2.png')} alt = "Force" /> </p>
              <p><img src = {require('../src/images/latest/shinjuku_3.png')} alt = "" /> </p>
              <p><img src = {require('../src/images/latest/h_4.png')} alt = "" /> </p>
              <p><img src = {require('../src/images/latest/security_service_5.png')} alt = "" /> </p>
              <p><img src = {require('../src/images/latest/leaf_6.png')} alt = "" /> </p>




              <p><img src = {require('../src/images/logo_confiance.png')} alt = "confiance logo" /> </p>
              <p><img src = {require('../src/images/logo_new_kindai.png')} alt = "New Modern Security Logo Co., Ltd." /> </p>
              {/* <p><img src = {require('../src/images/logo_andsec.png')} alt = "Asahi Safety Co., Ltd." /> </p> */}
              {/* <p><img src = {require('../src/images/logo_aik.png')} alt = "And Security Logo" /> </p> */}
              <p><img src = {require('../src/images/logo_homemade-tokyo.png')} alt = "Homemade Tokyo Co., Ltd." /> </p>
              <p><img src = {require('../src/images/logo_kanto_security.png')} alt = "Kanto Security" /> </p>
              <p><img src = {require('../src/images/logo_sku.png')} alt = "Kairiku Stock Keeping Logo" /> </p>
              <p><img src= {require('../src/images/logo_daito.png')} alt="株式会社ダイトー・セキュリティロゴ"/></p>   
              <p><img src= {require('../src/images/logo_daiwa.png')} alt="株式会社ダイワ・セキュリティーロゴ"/></p>
              <p><img src= {require('../src/images/logo_profix.png')} alt="株式会社日本プロフィックス・セキュリティーサービスロゴ"/></p>    
              {/* <p><img src= {require('../src/images/logo_s_company.png')} alt="S株式会社ロゴ"/></p> */}
              <p><img src= {require('../src/images/fix_RSP.png')} alt="" /></p>
              <p><img src= {require('../src/images/alliance.jpg')} alt="" /></p>
              <p><img src= {require('../src/images/yuki.png')} alt="" /></p>
              <p><img src= {require('../src/images/ace_security.png')} alt="" /></p>

              <p><img src= {require('../src/images/life_corporation_logo.png')} alt="" /></p>
              <p><img src = {require('../src/images/logo_midorigaoka.png')} alt = " Yuseikai Midorigaoka Hospital" /> </p>  
              <p><img src = {require('../src/images/logo_tachibana.png')} alt = "Tachibana Exterior Logo" /> </p>
              <p><img src = {require('../src/images/logo_sankyo_electric.png')} alt = "Sankyo Electric Logo" /> </p>





            </div>
          </div>
        </div>
      </div>
      {/* -- /add 2021.08 -- */}

    {/* AIK orderとは */}
    <div className="jumbotron jumbotron-fluid mb-0 py-4 py-md-5" id="about"
      style={{background:`url(${require('../src/images/about-ex-bg.jpg')}) center no-repeat`, backgroundSize:"cover"}}>
      <div className="container py-sm-3">
        <div className="row justify-content-center">
          <div className="col-xs-6 col-sm-4 col-md-4 col-lg-4 text-center">
            <img src={require('../src/images/about_tm.png')} className="img-fluid center-block" id="about-logo" alt="AIK orderロゴマーク"/>
          </div>
          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-6 py-sm-3 py-md-0">
            <h3 className="about-title">AIK order<span className="about-title jp">（アイク・オーダー）とは</span></h3>
            <p className="about-lead">個人や企業など警備を依頼したいお客様が、<br />
              警備会社にお仕事を依頼できるシステムです。<br />
              チャット機能を使用して、警備会社と手軽に相談が行え、<br />
              WEB上で契約成立まで行うことができます。
            </p>
          </div>
        </div>
      </div>
    </div>
    {/* AIK orderとは ここまで */}

    {/* 契約のながれ  */}
    <section className="py-5 bg-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-3 mb-lg-4 mt-lg-3 text-center">
            <h2 className="comprehensive_top_title font-weight-bold" style={{color:"#2b3b56"}}>ご契約のながれ</h2>
          </div>
        </div>
      </div>

      <div className="container">
        {/*  タブ型ナビゲーション  */}
        <div className="nav nav-tabs col-12 pr-0" id="tab-menus" role="tablist">
          {/*  タブ01  */}
          <a className="nav-item nav-link text-center font-weight-bold col-6" id="tab-menu01" data-toggle="tab"
            href="#panel-menu01" role="tab" aria-controls="panel-menu01" aria-selected="true">警備会社のお客様</a>
          {/*  タブ02  */}
          <a className="nav-item nav-link active text-center font-weight-bold col-6" id="tab-menu02" data-toggle="tab"
            href="#panel-menu02" role="tab" aria-controls="panel-menu02" aria-selected="false">警備を依頼したいお客様</a>
        </div>
        {/*  /タブ型ナビゲーション  */}

        {/*  タブパネル  */}
        <div className="tab-content" id="panel-menus">
          {/*  パネル01  */}
          <div className="tab-pane fade border border-top-0" id="panel-menu01" role="tabpanel"
            aria-labelledby="tab-menu01" style={{backgroundColor: "#eeeeee"}}>
            <div className="row p-3">
              <div className="col-12">
                <div className="flow">

                  <div className="flow-step security">
                    <h3 className="flow-prosess step01">step <span className="step-num">1</span></h3>
                    <div className="flow-item step-01">
                      <img className="aligncenter size-full" src={require('../src/images/flow-icon-security-01.png')} alt="" width="70"
                        height="64" />
                      <h4 style={{textAlign: "center"}}>会員登録</h4>
                      <p className="flow-description">登録時には審査を実施！</p>
                    </div>
                  </div>
                  <div className="flow-caret">
                    <img className="aligncenter size-full" src={require('../src/images/flow-icon-security-caret-right.png')} alt="" width="18"
                      height="32" />
                  </div>
                  <div className="flow-step security">
                    <h3 className="flow-prosess step02">step <span className="step-num">2</span></h3>
                    <div className="flow-item step-02">
                      <img loading="lazy" className="aligncenter size-full" src={require('../src/images/flow-icon-security-02.png')} alt=""
                        width="70" height="64" />
                      <h4 style={{textAlign: "center"}}>見やすい<br />案件情報</h4>
                      <p className="flow-description">たくさんの警備案件から<br />条件に合った案件に応募！</p>
                    </div>
                  </div>
                  <div className="flow-caret">
                    <img loading="lazy" className="aligncenter size-full" src={require('../src/images/flow-icon-security-caret-right.png')}
                      alt="" width="18" height="32" />
                  </div>
                  <div className="flow-step security">
                    <h3 className="flow-prosess step03">step <span className="step-num">3</span></h3>
                    <div className="flow-item step-03">
                      <img loading="lazy" className="aligncenter size-full" src={require('../src/images/flow-icon-security-03.png')} alt=""
                        width="70" height="64" />
                      <h4 style={{textAlign: "center"}}>便利な<br />チャット機能</h4>
                      <p className="flow-description">チャットで条件を<br />確認！</p>
                    </div>
                  </div>
                  <div className="flow-caret">
                    <img loading="lazy" className="aligncenter size-full" src={require('../src/images/flow-icon-security-caret-right.png')}
                      alt="" width="18" height="32" />
                  </div>
                  <div className="flow-step security">
                    <h3 className="flow-prosess step04">step <span className="step-num">4</span></h3>
                    <div className="flow-item step-04">
                      <img loading="lazy" className="aligncenter size-full" src={require('../src/images/flow-icon-security-04.png')} alt=""
                        width="70" height="64" />
                      <h4 style={{textAlign: "center"}}>らくらく<br />契約手続き</h4>
                      <p className="flow-description">前後書面の<br />管理・作成機能！</p>
                    </div>
                  </div>
                  <div className="flow-caret">
                    <img loading="lazy" className="aligncenter size-full" src={require('../src/images/flow-icon-security-caret-right.png')}
                      alt="" width="18" height="32" />
                  </div>
                  <div className="flow-step security">
                    <h3 className="step05">step <span className="step-num">5</span></h3>
                    <div className="flow-item step-05">
                      <img loading="lazy" className="aligncenter size-full" src={require('../src/images/flow-icon-security-05.png')} alt=""
                        width="70" height="64" />
                      <h4 style={{textAlign: "center"}}>案件終了</h4>
                      <p className="flow-description">警備報告書の<br />発行・管理機能！</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/*  /パネル01  */}

          {/*  パネル02  */}
          <div className="tab-pane fade show active border border-top-0" id="panel-menu02" role="tabpanel" aria-labelledby="tab-menu02"
            style={{backgroundColor: "#eeeeee"}}>
            <div className="row p-3">
              <div className="col-12">
                <div className="flow">

                  <div className="flow-step client">
                    <h3 className="flow-prosess step01">step <span className="step-num">1</span></h3>
                    <div className="flow-item step-01">
                      <img className="aligncenter size-full" src={require('../src/images/flow-icon-client-01.png')} alt="" width="70"
                        height="64" />
                      <h4 style={{textAlign: "center"}}>会員登録</h4>
                      <p className="flow-description">登録時には審査を実施！</p>
                    </div>
                  </div>
                  <div className="flow-caret">
                    <img className="aligncenter size-full" src={require('../src/images/flow-icon-client-caret-right.png')} alt="" width="18"
                      height="32" />
                  </div>
                  <div className="flow-step client">
                    <h3 className="flow-prosess step02">step <span className="step-num">2</span></h3>
                    <div className="flow-item step-02">
                      <img className="aligncenter size-full" src={require('../src/images/flow-icon-client-02.png')} alt="" width="70"
                        height="64" />
                      <h4 style={{textAlign: "center"}}>簡単案件登録</h4>
                      <p className="flow-description">必要情報を入力して<br />募集開始！</p>
                    </div>
                  </div>
                  <div className="flow-caret">
                    <img className="aligncenter size-full" src={require('../src/images/flow-icon-client-caret-right.png')} alt="" width="18"
                      height="32" />
                  </div>
                  <div className="flow-step client">
                    <h3 className="flow-prosess step03">step <span className="step-num">3</span></h3>
                    <div className="flow-item step-03">
                      <img className="aligncenter size-full" src={require('../src/images/flow-icon-client-03.png')} alt="" width="70"
                        height="64" />
                      <h4 style={{textAlign: "center"}}>便利な<br />チャット機能</h4>
                      <p className="flow-description">複数の警備会社から<br />パートナーを選定！</p>
                    </div>
                  </div>
                  <div className="flow-caret">
                    <img className="aligncenter size-full" src={require('../src/images/flow-icon-client-caret-right.png')} alt="" width="18"
                      height="32" />
                  </div>
                  <div className="flow-step client">
                    <h3 className="flow-prosess step04">step <span className="step-num">4</span></h3>
                    <div className="flow-item step-04">
                      <img className="aligncenter size-full" src={require('../src/images/flow-icon-client-04.png')} alt="" width="70"
                        height="64" />
                      <h4 style={{textAlign: "center"}}>らくらく<br />契約手続き</h4>
                      <p className="flow-description">ワンクリックで完結！</p>
                    </div>
                  </div>
                  <div className="flow-caret">
                    <img className="aligncenter size-full" src={require('../src/images/flow-icon-client-caret-right.png')} alt="" width="18"
                      height="32" />
                  </div>
                  <div className="flow-step client">
                    <h3 className="step05">step <span className="step-num">5</span></h3>
                    <div className="flow-item step-05">
                      <img className="aligncenter size-full" src={require('../src/images/flow-icon-client-05.png')} alt="" width="70"
                        height="64" />
                      <h4 style={{textAlign: "center"}}>案件終了</h4>
                      <p className="flow-description">警備完了報告も<br />ネットで受領！</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  /タブパネル  */}
      </div>

      {/* 会員登録CTA */}
      <div className="col-md-12 col-lg-10 mx-auto">
        <div className="row h-100 cta pb-4">

          <div className="cta-bubble align-content-center mr-5">
            <p style={{fontSize: "18px"}}>会員登録は無料です。<br />
              この機会にぜひ<br />
              ご登録ください！</p>
          </div>

          <div className="">

            <div className="row h-50 cta-security">
              <div className="cta-wrapper security">
                <img className="alignnone size-full" src={require('../src/images/icon-security-circle.png')} alt="警備会社アイコン" width="48"
                  height="48"/>
                <span className="induction security">警備会社のお客様</span>
                <img className="alignnone size-full cta-chevron" src={require('../src/images/icon-chevron-security.png')} alt="" width="16"
                  height="24"/>
                <a href="company/register" className="btn-register security">
                  <i className="fas fa-edit"></i> 新規ご登録
                </a>
              </div>
            </div>

            <div className="row h-50 cta-client">
              <div className="cta-wrapper client">
                <img className="alignnone size-full" src={require('../src/images/icon-client-circle.png')} alt="クライアントアイコン" width="48"
                  height="48"/>
                <span className="induction client">警備を依頼したいお客様</span>
                <img className="alignnone size-full cta-chevron" src={require('../src/images/icon-chevron-client.png')} width="16" height="24"
                  alt=""/>
                <a href="customer/register" className="btn-register client">
                  <i className="fas fa-edit"></i> 新規ご登録
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* 会員登録CTA ここまで */}

    </section>
    {/* ご契約のながれ ここまで */}

    {/* 警備カテゴリ　ここから */}
    <div className="jumbotron rounded-0 mb-0" style={{backgroundColor:"#2b3b56"}}>
      <div className="container">

        {/* コーナータイトル */}
        <div className="row">
          <div className="col-sm-12 mb-3 mb-lg-4 mt-lg-3 text-center mb-4">
            <h2 className="comprehensive_top_title font-weight-bold text-white">警備カテゴリ</h2>
          </div>
        </div>
        {/* コーナータイトルここまで */}

        <div className="row">

          {/*  カテゴリー　ブロック */}
          <div className="col-sm-12 col-lg-6 col-xl-6">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-lg-6 mb-4">
                <img className="rounded-lg alignleft size-full" src={require('../src/images/categry-01_facility.png')} alt="施設警備ピクト"
                  width="100%" height="auto" />
              </div>
              <div className="col-xs-12 col-sm-6 col-lg-6 mb-4">
                <h4 className="category-title">施設警備</h4>
                <p className="category-description text-white">
                  イベント会場、事務所、駐車場、遊園地等の契約先施設に常駐し、出入管理、巡回、開閉館管理、鍵の管理といった業務を中心に行い、盗難といった事故を警戒し防止する業務です。</p>
              </div>
            </div>
          </div>
          {/*  カテゴリー　ブロック　ここまで */}

          {/*  カテゴリー　ブロック */}
          <div className="col-sm-12 col-lg-6 col-xl-6">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-lg-6 mb-4">
                <img className="rounded-lg alignleft size-full" src={require('../src/images/categry-02_traffic.png')} alt="交通誘導警備ピクト"
                  width="100%" height="auto" />
              </div>
              <div className="col-xs-12 col-sm-6 col-lg-6 mb-4">
                <h4 className="category-title">交通誘導警備</h4>
                <p className="category-description text-white">駐車場や道路工事での現場といった交通での危険があるところにおいて、車両や通行人等の誘導を行い、交通の安全管理を行います。
                </p>
              </div>
            </div>
          </div>
          {/*  カテゴリー　ブロック　ここまで */}

        </div>

        <div className="row">

          {/*  カテゴリー　ブロック */}
          <div className="col-sm-12 col-lg-6 col-xl-6">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-lg-6 mb-4">
                <img className="rounded-lg alignleft size-full" src={require('../src/images/categry-03_crowd.png')} alt="雑踏警備ピクト" width="100%"
                  height="auto" />
              </div>
              <div className="col-xs-12 col-sm-6 col-lg-6 mb-4">
                <h4 className="category-title">雑踏警備</h4>
                <p className="category-description text-white">
                  お祭りやイベント会場といった、人が多く集まる場所において、誘導や案内、情報提供や広報といった業務を行い、雑踏の中での安全管理を行います。</p>
              </div>
            </div>
          </div>
          {/*  カテゴリー　ブロック　ここまで */}

          {/*  カテゴリー　ブロック */}
          <div className="col-sm-12 col-lg-6 col-xl-6">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-lg-6 mb-4">
                <img className="rounded-lg alignleft size-full" src={require('../src/images/categry-04_valuables.png')} alt="貴重品運搬警備ピクト"
                  width="100%" height="auto" />
              </div>
              <div className="col-xs-12 col-sm-6 col-lg-6 mb-4">
                <h4 className="category-title">貴重品運搬警備</h4>
                <p className="category-description text-white">
                  契約者の現金や宝石といった貴重品を運搬するために、複数の警備員が連携して、不測の事態がないように努めます。多くの場合、貴重品運搬専用の車両を使用し、安全に目的地まで運搬します。</p>
              </div>
            </div>
          </div>
          {/*  カテゴリー　ブロック　ここまで */}

        </div>

        <div className="row">

          {/*  カテゴリー　ブロック */}
          <div className="col-sm-12 col-lg-6 col-xl-6">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-lg-6 mb-4">
                <img className="rounded-lg alignleft size-full" src={require('../src/images/categry-05_personal.png')} alt="身辺警備ピクト"
                  width="100%" height="auto" />
              </div>
              <div className="col-xs-12 col-sm-6 col-lg-6 mb-4">
                <h4 className="category-title">身辺警備</h4>
                <p className="category-description text-white">一般的に「ボディーガード」と呼ばれるもので、著名人から一般の子供たちまで幅広く対象となります。</p>
              </div>
            </div>
          </div>
          {/*  カテゴリー　ブロック　ここまで */}

        </div>
      </div>

      {/* 会員登録CTA */}
      <div className="col-md-12 col-lg-10 mx-auto">
        <div className="row h-100 cta pb-4">

          <div className="cta-bubble align-content-center mr-5">
            <p style={{fontSize: "18px"}}>会員登録は無料です。<br />
              この機会にぜひ<br />
              ご登録ください！</p>
          </div>

          <div className="">
            <div className="row h-50 cta-security">
              <div className="cta-wrapper security">
                <img className="alignnone size-full" src={require('../src/images/icon-security-circle.png')} alt="警備会社アイコン" width="48"
                  height="48"/>
                <span className="induction security">警備会社のお客様</span>
                <img className="alignnone size-full cta-chevron" src={require('../src/images/icon-chevron-security.png')} alt="" width="16"
                  height="24"/>
                <a href="company/register" className="btn-register security">
                  <i className="fas fa-edit"></i> 新規ご登録
                </a>
              </div>
            </div>

            <div className="row h-50 cta-client">
              <div className="cta-wrapper client">
                <img className="alignnone size-full" src={require('../src/images/icon-client-circle.png')} alt="クライアントアイコン" width="48"
                  height="48"/>
                <span className="induction client">警備を依頼したいお客様</span>
                <img className="alignnone size-full cta-chevron" src={require('../src/images/icon-chevron-client.png')} width="16" height="24"
                  alt=""/>
                <a href="customer/register" className="btn-register client">
                  <i className="fas fa-edit"></i> 新規ご登録
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* 会員登録CTA ここまで */}
    </div>
    {/* 警備カテゴリ　ここまで */}

    {/* ニュース  */}
    {newsList.length > 0 && <NewsList newsList={newsList}></NewsList>}
    {/* ニュース ここまで  */}

    {/* 警備案件紹介 */}
    {/* hide project list */}
    {/* {projectList.length > 0 && <ProjectsList projectList={projectList}></ProjectsList>} */}
    {/* 警備案件紹介 ここまで  */}

    {/*Banner added*/}    
      <section className="py-4">
        <div style={{width:'70%',margin:'auto'}}>
          <a href=" https://aik-convert.com/" target="_blank">
            <img className="bannerCls" src={require('../src/images/OC_4.jpg')} alt=""/>
          </a>
        </div>
      </section>
     

    {/* トップへ戻る */}
    <nav id="page__top" className="navbar navbar-dark text-center top-link">
      <div className="navbar-brand page__top-item comprehensive_top_page__top-item"><i className="fas fa-chevron-up"></i>Topへ戻る
      </div>
    </nav>

    {/* フッター */}
    <footer id="socket" className="text-left">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-link-menu">
              <ul>
                <li>
                  <a href="customer/sitemap">サイトマップ</a>
                </li>
                <li>
                  <a href="customer/terms">利用規約</a>
                </li>
                <li>
                  <a href="https://aik-security.co.jp/privacypolicy" target="_blank">プライバシーポリシー</a>
                </li>
                <li>
                  <a href="https://aik-security.co.jp/contact1" target="_blank">AIK orderに関する問い合わせ</a>
                </li>
                <li>
                  <a href="https://aik-security.co.jp" target="_blank">運営会社</a>
                </li>
                <li> 
                  <a target= "_Blank" href= "Https://Tayori.Com/faq/9630ab0f014c74ac38dd64bd09cd56d00d7a6532"> FAQ </a> 
                </li>
              </ul>
            </div>
            <div className="copyright">
              <span>© AIK Co., Ltd.</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
        </Route>
      </Switch>
    </Router>
  );
}
