const Config = {
  apiBase: "http://localhost:8080/",
  //apiBase: "http://stg.aik-order.com/api/",
  //apiBase: 'https://securitysystem.innovaturelabs.com/api/',
  imageMaxFileSize: 5242880,
  companyBasePath: "/company",
  customerBasePath: "/customer",
  adminBasePath: "/admin"
};

export default Config;
