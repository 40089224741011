import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useAppContext from "../../AppContext";
import { Link, useLocation } from "react-router-dom";
import Config from "../../Config";
import NewsDetail from "../news/NewsDetail";

function NewsList({newsList}) {

  const appContext = useAppContext();
  
  const history = useHistory();

  useEffect(() => {
    // console.log(newsList);
  }, []);
  
  return (
    <section className="py-5 bg-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mb-3 mb-lg-4 mt-lg-3 text-center">
            <h2 className="comprehensive_top_title font-weight-bold" style={{color:"#2b3b56"}}>最新ニュース</h2>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12">
            <table id="news" className="table table-responsive border-bottom">
              <tbody>
                {newsList.map(function(news, i){
                  return <NewsDetail news={news} key={news.newsId}></NewsDetail>;
                })}
              </tbody>
            </table>
            {/* <a class="twitter-timeline" href="https://twitter.com/AIK_keibi_tokyo?ref_src=twsrc%5Etfw">Tweets by AIK_keibi_tokyo</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>  */}
          </div>
        </div>
        {/*  <div className="container">
            <div className="row">
              <div className="col-12 col-auto mx-auto mb-3 px-0">
                <a className="btn btn-block btn-lg btn-see-more text-white font-weight-bold" href="#" title="">
                  <i className="fas fa-chevron-circle-down"></i> ニュースをもっと見る
                </a>
              </div>
            </div>
          </div>  */}
      </div>
    </section>
  );
}

export default NewsList;
