import React from "react";
import axios from "axios";
import Config from "./Config";
// import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

const NO_OP = () => {};

const AppContext = React.createContext();
if (process.env.NODE_ENV !== "production") {
  AppContext.displayName = "AppContext";
}

const useAppContext = () => React.useContext(AppContext);

const LOGIN_API_PUT = "login";
const FORGOT_PASSWORD_API = "login/forgotPassword/customer";
const FORGOT_PASSWORD_RESET_API = "login/forgotPasswordReset";
const FORGOT_PASSWORD_VALIDATE_API = "login/forgotPasswordValidate";
const SESSION_KEY = "refreshToken";
const NAME = "name";

const basicContentType = { "Content-Type": "application/json" };
const AXIOS = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    get: basicContentType,
    post: basicContentType,
    put: basicContentType,
    delete: basicContentType,
    patch: basicContentType,
  },
});

AXIOS.interceptors.response.use(
  (response) => {
    ////to fix cache clear issue. commented because unable to register
    // if(!localStorage.getItem(SESSION_KEY) && window.location.pathname != "/"){
    //   removeAuthorization();
    //   window.location.pathname = "/login";
    // }
    return response;
  },
  function (error) {
    // if(error.response.data.message == "Invalid"){
    //   window.location.pathname = process.env.PUBLIC_URL+"/inactive";
    //   return Promise.reject(error);
    // }
    if(localStorage.getItem("role") && localStorage.getItem("role") == 3){
      window.location.pathname = Config.adminBasePath+"/dashboard";
      return Promise.reject(error);
    } else if(localStorage.getItem("role") && localStorage.getItem("role") == 2){
      window.location.pathname = Config.companyBasePath+"/dashboard";
      return Promise.reject(error);
    } else if(localStorage.getItem("role") && localStorage.getItem("role") == 1){
      window.location.pathname = Config.customerBasePath+"/dashboard";
      return Promise.reject(error);
    } else {
      if(window.location.pathname != "/"){
        window.location.pathname = "/";
      }
    }
    
    if (error.response.status === 403) {
      localStorage.removeItem(SESSION_KEY);
      removeAuthorization();
      // window.location.pathname = "/";
      return Promise.reject(error);
    }
    if (error.response.status === 404) {
      // window.location.pathname = "/";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

function AppContextProvider({ init, children }) {
  const [auth, setAuth] = React.useState(init);
  // const history = useHistory();

  React.useEffect(() => {
    if (auth !== null) {
      // const expiry = moment(auth.expiry).valueOf();
      const time = 100000; //(expiry >= 300000 ? expiry - 30000 : expiry * 0.9);
      const timeout = setTimeout(() => {
        refreshAccessToken();
      }, time);
      if(localStorage.getItem("role") && localStorage.getItem("role") == 3){
        window.location.pathname = Config.adminBasePath+"/dashboard";
      } else if(localStorage.getItem("role") && localStorage.getItem("role") == 2){
        window.location.pathname = Config.companyBasePath+"/dashboard";
      } else if(localStorage.getItem("role") && localStorage.getItem("role") == 1){
        window.location.pathname = Config.customerBasePath+"/dashboard";
      } else {
        // console.log("here== auth else")
        // window.location.pathname = "/";
        // return Promise.reject(error);
      }
      return () => {
        clearTimeout(timeout);
      };
    }

    return NO_OP;
  }, [auth]);

  function signIn(email, password, LOGIN_API) {
    removeAuthorization();
    let data = JSON.stringify({
      password: password,
      email: email,
    });

    return AXIOS.post(LOGIN_API, data).then(
      (response) => {
        AXIOS.defaults.headers.common["AuthToken"] =
          response.data.accessToken.value;
        setAuthorization(response.data.accessToken.value);
        localStorage.setItem(SESSION_KEY, response.data.refreshToken.value);
        localStorage.setItem(NAME, response.data.name);
        localStorage.setItem("role", response.data.role);

        setAuth(response.data.accessToken);
        return { status: true, info: response };
      },
      (error) => {
        removeAuthorization();
        return { status: false, info: error };
      }
    );
  }

  function forgotPassword(email) {
    let data = JSON.stringify({
      email: email,
    });

    return AXIOS.post(FORGOT_PASSWORD_API, data).then(
      (response) => {
        return { status: true, info: response };
      },
      (error) => {
        return { status: false, info: error };
      }
    );
  }

  function forgotPasswordReset(newPassword, confirmPassword, token) {
    let data = JSON.stringify({
      newPassword: newPassword,
      confirmNewPassword: confirmPassword,
      token: token,
    });

    return AXIOS.post(`${FORGOT_PASSWORD_RESET_API}`, data).then(
      (response) => {
        // onClose();
        return { status: true, info: response };
      },
      (error) => {
        return { status: false, info: error };
      }
    );
  }

  function forgotPasswordforgotPasswordValidate(token) {
    return AXIOS.get(`${FORGOT_PASSWORD_VALIDATE_API}/${token}`).then(
      (response) => {
        return { status: true, info: response };
      },
      (error) => {
        return { status: false, info: error };
      }
    );
  }

  function downloadCSV(url) {
    return AXIOS.get(url).then(
      (response) => {
        const content_disposition = response.headers['content-disposition'];
        const filename = content_disposition ? content_disposition.split("=")[1] : "file.csv";
        var blob = new Blob([response.data]);
        var a = window.document.createElement("a");

        a.href = window.URL.createObjectURL(blob, {
          type: "application/csv"
        });
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return { status: true, info: response };
      },
      (error) => {
         return { status: false, info: error };
      }
    );
  }
  const isLoggedin = () => auth !== null;

  const getAccessToken = () => auth;

  const getAxios = () => AXIOS;

  function logout() {
    removeAuthorization();
    setAuth(null);
  }

  const context = {
    signIn,
    forgotPassword,
    forgotPasswordReset,
    forgotPasswordforgotPasswordValidate,
    downloadCSV,
    isLoggedin,
    getAccessToken,
    getAxios,
    logout,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}
function refreshAccessToken() {
  let refreshToken = localStorage.getItem(SESSION_KEY);
  if (refreshToken === null) {
    return Promise.resolve(null);
  }
  return AXIOS.put(LOGIN_API_PUT, refreshToken, {
    headers: {
      authorization: null,
    },
  }).then(
    (response) => {
      AXIOS.defaults.headers.common["AuthToken"] =
        response.data.accessToken.value;
      setAuthorization(response.data.accessToken.value);
      localStorage.setItem(SESSION_KEY, response.data.refreshToken.value);
      localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
      if(localStorage.getItem("role") && localStorage.getItem("role") == 3){
        localStorage.setItem("role", response.data.role);
        window.location.pathname = Config.adminBasePath+"/dashboard";
      } else if(localStorage.getItem("role") && localStorage.getItem("role") == 2){
        localStorage.setItem("role", response.data.role);
        window.location.pathname = Config.companyBasePath+"/dashboard";
      } else if(localStorage.getItem("role") && localStorage.getItem("role") == 1){
        localStorage.setItem("role", response.data.role);
        window.location.pathname = Config.customerBasePath+"/dashboard";
      } else {
        if(window.location.pathname != "/"){
          window.location.pathname = "/";
        }
      }
      return response.data.accessToken;
    },
    () => {
      if(localStorage.getItem("accessToken") && localStorage.getItem("role") && localStorage.getItem("role") != 3){
        // window.location.pathname = Config.companyBasePath+"/dashboard";
        return JSON.parse(localStorage.getItem("accessToken"));
      } else {
        removeAuthorization();
        localStorage.removeItem(SESSION_KEY);
        return null;
      }
    }
  );
}

function setAuthorization(accessTokenValue) {
  AXIOS.defaults.headers.common["authorization"] = AXIOS.defaults.headers.get[
    "authorization"
  ] = AXIOS.defaults.headers.post["authorization"] = AXIOS.defaults.headers.put[
    "authorization"
  ] = AXIOS.defaults.headers.delete[
    "authorization"
  ] = AXIOS.defaults.headers.patch["authorization"] =
    "Bearer " + accessTokenValue;
}

function removeAuthorization() {
  localStorage.removeItem("role");
  localStorage.removeItem("accessToken");
  localStorage.removeItem(SESSION_KEY);
  delete AXIOS.defaults.headers.common["authorization"];
  delete AXIOS.defaults.headers.get["authorization"];
  delete AXIOS.defaults.headers.post["authorization"];
  delete AXIOS.defaults.headers.put["authorization"];
  delete AXIOS.defaults.headers.delete["authorization"];
  delete AXIOS.defaults.headers.patch["authorization"];
}

export default useAppContext;
export { refreshAccessToken, AppContextProvider };
