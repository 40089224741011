import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useAppContext from "../../AppContext";
import { Link, useLocation } from "react-router-dom";
import Config from "../../Config";
import moment from "moment";
import * as Constants from "./../constants"

function NewsDetail({news}) {

  const appContext = useAppContext();
  
  const history = useHistory();

  useEffect(() => {
    // console.log(news);<span class="new">NEW</span>
  }, []);
  
  return (
    <tr>
      <td>{moment(news.publish_start_date).format(Constants.UI_DATE_FORMAT)}</td>
      <td>
        <div dangerouslySetInnerHTML={{__html: `${news.content}`}}/>
      </td>
    </tr>
  );
}

export default NewsDetail;
