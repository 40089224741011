import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useAppContext from "../../AppContext";
import { Link, useLocation } from "react-router-dom";
import Config from "../../Config";
import languageJp from "../../locale/language.json";

function CompanyLogin() {

  const appContext = useAppContext();
  const [disabled, setDisabled] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [formError, setFormError] = React.useState(null);
  const history = useHistory();
  const [errors, setErrors] = React.useState({
    email: "",
    password: "",
  });

  const [resend, setResend] = React.useState('');
  const [expired, setExpired] = React.useState('');

  const LOGIN_API = "login/company";


  // useEffect(() => {
  //   if(localStorage.getItem("role") && localStorage.getItem("role") != 2){
  //     if(localStorage.getItem("role") != 3){
  //       window.location.pathname = Config.customerBasePath+"/";
  //     }else{
  //       window.location.pathname = Config.adminBasePath+"/";
  //     }
  //   }
  //   if (appContext.isLoggedin()) {
  //     history.push(Config.companyBasePath + "/dashboard");
  //   } else {
  //     history.push("/");
  //   }
  // }, []);

  const handlePasswordChange = (event) => {
    const pwd = event.target.value;
    setPassword(pwd);
    setErrors({
        email: errors.email,
        password: pwd.trim() === "" ? "パスワードは必須項目です" : "",
      });
  };

  const handleEmailChange = (event) => {
    const eml = event.target.value;
    setEmail(eml);
    setErrors({
      email: eml.trim() === "" ? "メールアドレスは必須項目です" : "",
      password: errors.password,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDisabled(true);
    setFormError(null);
   
    if (password !== "" && email !== "") {    
      appContext.signIn(email, password, LOGIN_API).then((result) => {
        if (!result.status) {
          if(result.info && result.info.response && result.info.response.data.message == "Invalid"){
            window.location.pathname = Config.companyBasePath + "/inactive";
          }else if(result.info && result.info.response && result.info.response.data.message=="メールが確認されていません"){
            setFormError("既にURLを記載したメールを送信済みです。"+"\n"+"送信メールに記載されたURLからお手続きをお願い致します。");
            window.localStorage.setItem("resendEmail",email);
            setResend(true);
          }else if(result.info && result.info.response && result.info.response.data.message=="Token is expired"){
            setFormError("あなたの電子メールは確認されていません、ログインのためにあなたの電子メールを確認してください");
            window.localStorage.setItem("expMail",email); 
            setExpired(true);
          }else{
            setFormError("メールアドレスかパスワードが正しくありません");
            setErrors({
              email: "",
              password: "",
            });
            // setDisabled(false); // to prevent disabling this button if error occurs
          }
        } else {
          window.location.pathname = Config.companyBasePath + "/dashboard";
        }
        setDisabled(false);
        // if (disabled) {
        //   // to prevent no-op error, this condition is checked
        //   setDisabled(false);
        // }
      });
    } else {
      setDisabled(false);
      setErrors({
        email: email === "" ? "メールアドレスは必須項目です" : "",
        password: password === "" ? "パスワードは必須項目です" : "",
      });
    }
  };
  return (
    <div className="col-lg-5 col-md-6 col-sm-12 text-left mb-3 mb-md-0 offset-lg-1 px-xs-0">
      <div className="card border-0">
        <h3 className="card-header font-weight-bold form-title security">警備会社のお客様</h3>
        <div className="card-body p-4">
          <div className="text-center">
            {/* <p className="text-danger mb_S">メールアドレスかパスワードが正しくありません</p> */}
            {formError !== null ? (
                <p className="text-danger mb_S">
                  {formError}
                </p>
              ) : (
                ""
              )}
          </div>
          <form name="login_company">
            <div className="form-group">
              {/* <input type="text" className="form-control" id="formGroupUserNameInput" placeholder="メールアドレス"/> */}
              <input 
                type="text"
                onChange={handleEmailChange}
                value={email}
                className="form-control" 
                id="formGroupUserNameInput" 
                placeholder="メールアドレス"
              />
              {errors.email.length > 0 && (
                <p
                  id="mail_message_area"
                  className="text-danger mt-2"
                >
                  メールアドレスは必須項目です
                </p>
              )}
              {/* <p id="mail_message_area" className="text-danger mt-2">メールアドレスは必須項目です</p> */}
            </div>
            <div className="form-group">
              {/* <input type="text" className="form-control" id="formGroupPasswordInput" placeholder="パスワード"/> */}
              <input
                type="password"
                onChange={handlePasswordChange}
                value={password}
                className="form-control" 
                id="formGroupPasswordInput" 
                placeholder="パスワード"
              />
              {/* <p id="password1_message_area" className="text-danger mt-2">パスワードは必須項目です</p> */}
              {errors.password.length > 0 && (
                <p
                  id="password1_message_area"
                  className="text-danger mt-2"
                >
                  パスワードは必須項目です
                </p>
              )}
            </div>
            {/* <button type="submit"
              className="btn btn-login-security btn-lg btn-block my-2 text-white font-weight-bold"><i
                className="fas fa-sign-in-alt"></i> ログイン</button> */}
            <button
              disabled={disabled}
              onClick={handleSubmit}
              className="btn btn-login-security btn-lg btn-block my-2 text-white font-weight-bold"
            >
              <i className="fas fa-sign-in-alt"></i>
              ログイン
            </button>
            <a href={Config.companyBasePath + "/forgotPassword"} className="fgt-pwd security"><i className="fas fa-question-circle"></i>
              &nbsp;パスワードをお忘れの方はこちら</a>
            <a href={Config.companyBasePath + "/register"} className="btn btn-register2 btn-lg btn-block mt-3 font-weight-bold"><i
                className="fas fa-edit"></i> 新規ご登録</a>
            {resend==true?<p>
              <a href={Config.companyBasePath+"/resendmail"}>{languageJp.RE_SEND_VERIFICATION_MAIL}</a>
            </p>:''}
            {expired==true?<p>
              <a href={Config.companyBasePath+"/resendmailcompleted"}>{languageJp.RE_SEND_VERIFICATION_MAIL}</a>
            </p>:''}
          </form>
        </div>
      </div>
    </div>
  );
}

export default CompanyLogin;
