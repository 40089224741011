import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useAppContext from "../../AppContext";
import { Link, useLocation } from "react-router-dom";
import Config from "../../Config";
import ProjectsDetail from "./ProjectsDetail";

function ProjectsList({projectList}) {

  const appContext = useAppContext();
  
  const history = useHistory();

  useEffect(() => {
    // console.log(projectList);
  }, []);
  
  return (
    <section className="py-5" style={{backgroundColor:"#eeeeee"}}>
      <div className="container">

        <div className="row">
          <div className="col-12 mb-3 mb-lg-4 mt-lg-3 text-center">
            <h2 className="comprehensive_top_title font-weight-bold" style={{color:"#2b3b56"}}>警備案件紹介</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <div className="row">

              {/* 警備案件ブロック */}
              {projectList.slice(0, 6).map(function(project, i){
                return <ProjectsDetail project={project} key={project.projectId}></ProjectsDetail>;
              })}
              {/* 警備案件ブロックここまで */}

            </div>
          </div>
        </div>
        {
          projectList.length >= 6 && 
          <div className="container">
            <div className="row">
              <div className="col-12 col-auto mx-auto mb-3 px-0">
                <a className="btn btn-block btn-lg btn-see-more text-white font-weight-bold" href="company/caseList" title="">
                  <i className="fas fa-chevron-circle-down"></i> 警備案件をもっと見る
                </a>
              </div>
            </div>
          </div>
        }
      </div>
    </section>
  );
}

export default ProjectsList;
